import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import FilePlayer from 'react-player/lib/players/FilePlayer';


const styles = theme => ({
  background: {
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
    
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: '300px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '300px',
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
  video: {
    position: 'absolute',

    zIndex: -5,
  }
});

function ProductHero(props) {
  const { classes } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <FilePlayer 
        url={[
          {src: 'images/compressed3.mp4', type: 'video/mp4'}
        ]}
        playing={true}
        controls={false} 
        loop
        volume={0}
        muted
        autoPlay
        playsinline
        alt="increase priority" 
        width={1920} 
        height={1080} 
        className={classes.video}
      />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        YOUR TV IS A DIGITAL PHOTO FRAME
      </Typography>
      <Typography color="inherit" align="center" className={classes.h5}>
        Enjoy your Google Photos albums beautifully on your Apple TV 
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        className={classes.button}
        component="a"
        href="https://apps.apple.com/us/app/tv-photoframe/id1455232077?ls=1"
      >
        APP STORE
      </Button>
      <Typography variant="body2" color="inherit" className={classes.more}>
        Download the app now!
      </Typography>
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
